import React from 'react'
import './Plans.css'
import { useNavigate } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import whiteTick from '../assets/whiteTick.png'
import {plansData} from  '../data/plansData'
import {useState} from 'react';
import { Link } from 'react-scroll';

function Plans() {
    
    const navigate = useNavigate();
  return (

    
    <div className='plans-container'>
                

        <div className="blur     plans-blur1"></div>
        <div className="blur     plans-blur2"></div>

<div className="programs-header" style={{gap:'2rem'}}>

    
</div>
{/* plans cards */}
<div className="plans">

{plansData.map((plan,i)=>(

<div className="plan" key={i}>

    {plan.icon}
    <span> {plan.name}</span>
    <span>{plan.price}  DA</span>
    <div className="features">
{plan.features.map((feature,i)=>(

<div className="feature">

    
    <span>-</span><span key={i}>{feature}</span>
    <br/>
    
    </div>
))}

    </div>

    
    <button className="btn"  onClick={() => navigate("/formulaire")} >S'inscrire</button>

</div>

))}


</div>

    </div>
  )
}

export default Plans