import React from 'react'
import Programms from './Programms';
import Testimonials from './Testimonials';
import './Coach.css';
import Footer from './Footer';
function Coach() {
  return (
    <div >
        <Testimonials/>
        <div className='coach'> </div>
<Programms/>


<Footer/>







    </div>
  )
}

export default Coach