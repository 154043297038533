import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Blogs from "./pages/Blogs";
import Coach from "./pages/Coach";

import Contact from "./pages/Contact";
import './index.css'
import React from 'react'
import Footer from "./pages/Footer";

import { useState } from 'react';
import { Link } from 'react-scroll';

import { NavLink } from 'react-router-dom';


export default function App() {
  let activeClassName = "nav-active";
  const mobile =window.innerWidth<= 768 ? true : false
    const [menuOpened,setMenuOpened]=useState(false)
  return (
    
    <BrowserRouter>
   
 
   
      <Routes >
       
       <Route  path="/" element={<Layout />}>
         <Route   index element={<Home />} />
          <Route path="blogs" element={<Blogs />} />
           <Route path="contact" element={<Contact />} />
           <Route path="Coach" element={<Coach />} />

        </Route>
        
      </Routes>
     
     
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);