import React from 'react'
import './Programms.css'
import {programsData} from '../data/programsData';
import RightArrow from '../assets/rightArrow.png';

const programs = () => {
  return (
    <div className='programs' id='programs'>
            {/*header */}

<div className="programs-header">

<span className='stroke-text'>Explorez notre</span><span>Programme</span><span className='stroke-text'>Pour vous Sculpter</span>
</div>
<div className="program-categories">
{programsData.map((program)=>(<div className='category'>
  {program.image}
<span>{program.heading}</span>
<span>{program.details}</span>
<div className="join-now"><span>Inscrivez-vous </span>
<img src={RightArrow} />

</div>


</div>
))}
</div>



</div>
    
  )
}

export default programs