import { Outlet, Link } from "react-router-dom";
import './Layout.css'
import { useState } from 'react';
import Logo from '../assets/logo.png'
import Bars from '../assets/bars.png'
import { NavLink } from "react-router-dom";

const Layout = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  
  return (
    <>
    
    
 <nav className="navbar">
  <div className="nav-container">
  <NavLink exact to="/" className="nav-logo">
           
            <img src= {Logo}  alt='' className='logo'  />

          </NavLink>
        <ul className= {click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link  activeClassName="active"
                className="nav-links"
                onClick={handleClick}
                to="/"
            >Acceuil</Link>
          </li>
          <li className="nav-item">
            <Link to="/blogs"  activeClassName="active"
                className="nav-links"
                onClick={handleClick}
            >Horaires Et Packs</Link>
          </li>
          <li className="nav-item">
            <Link to="/coach"  className="nav-links"   activeClassName="active"  onClick={handleClick} >Coaching & programmes</Link>
          </li>
         
         
          <li className="nav-item">
            <Link to="/contact"  className="nav-links"  activeClassName="active"  onClick={handleClick} 
              >Contact</Link>
          </li>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}><img src={Bars} alt=""  style={{width: '1.5rem', height: '1.5rem'}}/></i>
          </div>
          </div>
        </nav>

     


      
      <Outlet />
    </>
  )
};

export default Layout;
