import React from 'react'
import './Reasons.css'
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import nb from "../assets/nb.png";
import adidas from "../assets/adidas.png";
import nike from "../assets/nike.png";
import tick from "../assets/tick.png";




const Reasons = () => {
  return (
    <div className='Reasons' id='reasons'>
<div className="left-r">
<img src={image1} alt="" />
<img src={image2} alt="" />
<img src={image3} alt="" />
<img src={image4} alt="" />



</div>
<div className="right-r">
<span>Pourquoi Nous Choisir?</span>
<div><span className='stroke-text'>Pourquoi  </span>
<span>   Nous Choisir  </span>
</div>
*<div className='details-r'>
<div><img src={tick} alt=""></img><span>entraîneurs expérimentés</span></div>
<div><img src={tick} alt="" /><span>s'entraîner plus intelligemment et plus rapidement qu'avant</span></div>
<div><img src={tick} alt=""></img><span>1 programme gratuit pour tout nouveau membre</span></div>
  <div><img src={tick} alt="" /><span>partenaires fiables</span></div>
  </div>







</div>





    </div>
  )
}

export default Reasons