

import React from 'react'
import './Home.css';
import hero_image from "../assets/hero_image.png";
import hero_image_back from "../assets/hero_image_back.png";
import Calories from "../assets/calories.png";
import Heart from "../assets/heart.png"
import {motion} from 'framer-motion'
import Footer from './Footer';
import Reasons from './Reasons';

function Hero() {
  
  

  const transition ={type: 'spring', duration:3};
  
  const mobile =window.innerWidth<= 768 ? true : false
  
  return (
    <div>
    
    <div className='hero' id='home'>
    

<div className="h-left">


{/* span decoré */}
<div className="the-best-ad">

  <motion.div

initial={{left: mobile? "165px" : "238px"}}
whileInView={{left: '9px'}}
transition={{...transition , type:'tween'}}
></motion.div>

<div></div>
<span>Meilleure salle Fitness</span>


</div>

{/* large phrase */}
<div className="hero-text">

<div><span className='stroke-text'>Formez  </span><span> votre </span></div>
<div><span>corps idéal</span></div>

<div><span>Ici, nous vous aiderons à former et à construire votre corps idéal et à vivre pleinement votre vie.</span></div>


</div>
{/*figures*/}
<div className="figures">

<div>
  <span>+20</span>
  <span> entraîneurs experts</span>
  </div>

  <div>
  <span>+ 100 </span>
  <span>adhérents</span>
  </div>
  <div>
  <span>+50</span>
  <span>programmes fitness</span>
  </div>
</div>

{/*hero buttons */}
<div className="hero-buttons">


{/*<buttons className="btn"  >Inscrivez-vous</buttons>
<buttons className="btn">Plus D'informations</buttons>
  <a href='Form'>ici</a>*/}


</div>

</div>
<div className="h-right">

{/*<button className='btn'   
> s'inscrire  </button>*/}

<motion.div
initial={{right:-1}}
whileInView={{right : "4rem"}}
transition={transition}
className="heart-rate">
<img src={Heart} alt=''/>
<span>Rythme cardiaque</span><span>116 bmp</span>
</motion.div>

<img src={hero_image}  alt=""   className='hero-image' />

<motion.img
initial={{right:'11rem'}}
whileInView={{right : "20rem"}}
transition={transition}
src={hero_image_back} alt=""
className="hero-image-back"></motion.img>




<img src={hero_image_back}  alt=""   className='hero-image-back' />






<div className="calories">

<img src={Calories} alt='' />
<div>

<span>Calories brûlées</span> <span>220 kcal</span>


</div>

</div>

</div>


    </div>
    <Reasons/>
    <Footer/>
    
    </div>
  )
}

export default Hero