import React from 'react'
import Emploi from './Emploi'
import Footer from './Footer'
import Home from './Home'
function Blogs() {
  return (
    <div>


<Emploi/>
<Footer/>

    </div>
  )
}

export default Blogs