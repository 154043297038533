import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Doté de  10 ans D'experiance dans le domaine Santé et Fitness, Trés Bon Résultats avec les clients",
    name: 'Ali  Hassan',
    status : 'Coach Fitness'
  },
  {
    image: image2,
    review: 'Grade 3 eme dane ,Diplôme d’instructeur fédéral de boxe thaïlandaise et experiance de 5 ans Coaching',
    name: 'Moali Riad',
    status: 'COACH Kick Boxing'
  },
  {
    image : image3,
    review:' Instructeur Zumba passionnée et enthousiaste avec 11 ans d experiance .',
    name: 'Rahal  Sarah',
    status: "Coach Zumba"
  }
];
