import './Contact.css'
import React, { useState } from 'react'
import image2 from '../assets/image2.png'
import Footer from './Footer'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { FaMapMarkerAlt,FaPhoneAlt ,FaRegEnvelope} from 'react-icons/fa';

function Formulaire() {
  const Result=()=>{

    return(

      <p>Message Envoyé avec Succée</p>
    )
  }
const [result,showResult]=useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_h8tgd5g', 'template_io19zp7', form.current, 'mrTSEJ2rQrdiGiNBT')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset();
      showResult(true);
  };

  return (

    <section>
    
    <div class="section-header">
      <div class="container">
        <h2>Contactez Nous</h2>
        <p>Nos Chers Clients Si Vous Souhaitez nous Contactez  Nous Sommes à Votre disposition 7/7j de 8h à 20h</p>
      </div>
    </div>
    
    <div class="container">
      <div class="row">
        
        <div class="contact-info">
          <div class="contact-info-item">
            <div class="contact-info-icon">
           
              <i class="fas fa-home"> <FaMapMarkerAlt /></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Addresse</h4>
              <p>Bir El Jir Oran,<br/>Oran</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-phone"><FaPhoneAlt/></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Telephone</h4>
              <p>05-30-22-10</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i class="fas fa-envelope"><FaRegEnvelope/></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Email</h4>
             <p>fitclub@gmail.com</p>
            </div>
          </div>
        </div>
        
        <div class="contact-form">
          <form action="" id="contact-form"  onSubmit={sendEmail} ref={form}>
            <h2>Envoyer un  Message</h2>
            <div class="input-box">
              <input type="text" required="true" name="fullname"></input>
              <span>Nom Complet</span>
            </div>
            
            <div class="input-box">
              <input type="email" required="true" name="email"></input>
              <span>Email</span>
            </div>
            <div class="input-box">
              <input type="tel" required="true" name="phone"></input>
              <span>Telephone</span>
            </div>
            
            <div class="input-box">
              <textarea required="true" name="message"></textarea>
              <span>Tapez votre message ...</span>
            </div>
            
            <div class="input-box">
              <input type="submit" value="Envoyer" name=""></input>



            </div>

            <div>


             { result ? <Result/>: null }
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </section>
  )
}

export default Formulaire