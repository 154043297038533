import React from 'react'
import './Emploi.css'
import Plans from './Plans'
import box from '../assets/box.png'

function Emploi() {
  return (
    <div className='table-container'>
       
       <div class="container">
        <h2>Horaires et Packs</h2>
       
      </div>



<Plans/>

<span className='heading'>Programme de la semaine</span>
<table className='table'> 

<thead>

<tr>
<th>Le Jour </th>
<th>L'heure</th>
<th>Sport</th>
<th> Coach</th>

</tr>

</thead>
<tbody>

<tr>
<td data-label="jour">Dimanche</td>
<td data-label="L'heure">8-9h</td>
<td data-label="Sport">Strong</td>
<td data-label="Coach">Samir</td>

</tr>

<tr>
<td data-label="jour">Dimanche</td>
<td data-label="L'heure">14-15h</td>
<td data-label="Sport">Aerobic</td>
<td data-label="Coach">Ines</td>

</tr>
<tr>
<td data-label="jour">Lundi</td>
<td data-label="L'heure">10-11h</td>
<td data-label="Sport">Kickboxing</td>
<td data-label="Coach">Samir</td>

</tr>
<tr>
<td data-label="jour">Jeudi</td>
<td data-label="L'heure">18-19h</td>
<td data-label="Sport">Zumba</td>
<td data-label="Coach">Ines</td>

</tr>
<tr>
<td data-label="jour">Vendredi</td>
<td data-label="L'heure">15-16h</td>
<td data-label="Sport">Perte du Poids</td>
<td data-label="Coach">Amine</td>

</tr>

</tbody>

</table>
<div>

<h3 className='heading'>Cardio et Musculation Femme</h3>
<span className='sentence'>Du Samedi au Jeudi:</span><span>       </span>
<span className='sentence'>de 08h  à  17h30</span>
<h3 className='heading'>Cardio et Musculation Homme</h3>
<span className='sentence'>Du Samedi au Jeudi:</span><span>         </span>
<span className='sentence'>de 17h30h à 21h</span>
</div>

    </div>
  )
}

export default Emploi